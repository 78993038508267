<template>
    <el-checkbox-group v-model="list" v-if="options.length > 0">
        <el-checkbox
            v-for="op in options"
            :key="op[valueKey]"
            :label="op[valueKey]"
        >
            {{ op[labelKey] }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
    data() {
        return {
            options: [],
            list: []
        };
    },
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        labelKey() {
            return this.item.labelKey || "label";
        },
        valueKey() {
            return this.item.valueKey || "value";
        }
    },
    watch: {
        list: {
            deep: true,
            handler() {
                this.$set(this.data, this.item.key, this.list);
            }
        },
        data: {
            deep: true,
            handler() {
                this.list = this.data[this.item.key] || [];
            }
        }
    },
    mounted() {
        if (this.item.remote) {
            this.item.remote().then(data => {
                this.options = data.datas;
            });
        } else {
            this.options = this.item.options;
        }
        if (!this.data[this.item.key] && this.item.default != null) {
            this.$set(this.data, this.item.key, this.item.default);
            this.list = this.item.default;
        }
        // if (this.data[this.item.key]) {
        //     this.list = this.data[this.item.key];
        // }
    }
};
</script>

<style lang="scss">
.el-checkbox-group {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 10px;
    .el-checkbox {
        padding: 5px;
    }
}
</style>